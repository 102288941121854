import React from "react";
import logo from './logo.svg';
import './App.scss';

const App = () => {
  return (
    <div className="logo">
      <img src={logo} alt="Doneliva Logo" height="360px"/>
    </div>
  );
}

export default App;